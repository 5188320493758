import qs from 'qs'
import React, { useEffect, useState } from 'react'
import { Environment, EnvironmentVariables } from '../../../lib/Constants'
import SecondaryButton from '../SecondaryButton'
import FacebookSVG from './facebook.svg'
import './FacebookLoginButton.scss'

const state = 1

interface Props {
  onResponse: (resp: {
    data_access_expiration_time: string,
    expires_in: string,
    token: string,
  }) => void,
  text?: string,
  isLoading: boolean
}

const FacebookLoginButton: React.FC<Props> = ({
  onResponse,
  text = `Facebook`,
  isLoading = false,
}) => {
  const [url, setUrl] = useState(``)

  useEffect(() => {
    if(!window){
      return
    }

    const parameters = qs.stringify({
      client_id: EnvironmentVariables.FACEBOOK_APP_ID,
      redirect_uri: `${
        EnvironmentVariables.ENVIRONMENT === Environment.development ? `http` : `https`
      }://${window.location.host}/login`,
      response_type: `token,granted_scopes`,
      scope: [`public_profile`, `email`].join(`,`),
      state,
    })
    setUrl(`https://www.facebook.com/v7.0/dialog/oauth?${parameters}`)

    if(window.location.hash.length > 1){
      // TODO: make this less hacky
      const urlParameters = qs.parse(window.location.hash.slice(1))
      const { access_token: token, data_access_expiration_time, expires_in } = urlParameters

      // remove hash
      window.history.replaceState(``, document.title, `${window.location.pathname}${window.location.search}`)

      if(token){
        onResponse({
          data_access_expiration_time,
          expires_in,
          token,
        })
      }
    }
  }, [onResponse])

  return (
    <SecondaryButton
      to={url}
      className="facebook-login-button"
      isLoading={isLoading}
      loadingText={
        <>
          <img src={FacebookSVG} alt="Facebook Logo" />
          <span>Logging in…</span>
        </>
      }
    >
      <img src={FacebookSVG} alt="Facebook Logo" />
      <span>{text}</span>
    </SecondaryButton>
  )
}

export default FacebookLoginButton
import React from 'react'
import './CheckboxInput.scss'

// eslint-disable-next-line quotes
interface Props extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'value' | 'onChange'> {
  label: string | React.ReactNode,
  value: boolean,
  onChange: (b: boolean) => void
}

const CheckboxInput: React.FC<Props> = ({
  label,
  value,
  onChange = () => {},
  ...otherProperties
}) => {

  const onInputChange = React.useCallback(
    ({ target: { checked } }) => onChange(checked),
    [onChange],
  )

  return (
    <div className="checkbox-input-container">
      <input
        type="checkbox"
        checked={value}
        onChange={onInputChange}
        {...otherProperties}
      />
      <label>
        {label}
      </label>
    </div>
  )
}

export default CheckboxInput

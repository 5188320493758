import { graphql, useStaticQuery } from 'gatsby'
import React, { useEffect, useState } from 'react'
import { ExplanatoryBanner } from '../../components/Banner'
import CompanyProfile from '../../components/CompanyProfile/CompanyProfile'
import Layout from '../../components/Layout'
import SEO from '../../components/SEO'
import Spinner from '../../components/Spinner'
import Api from '../../lib/Api'
import { Company } from '../../types/unicornvalley'
import './Home.scss'

const Home = () => {
  const {
    allUvCompanies: { edges },
  } = useStaticQuery(graphql`
    query AllCompanies {
      allUvCompanies(filter: {is_removed: {eq: false}}) {
        edges {
          node {
            name
            is_removed
            description
            id
            company_id
            picture
            tagline
            creator {
              bio
              name
              username
              user_id
              picture
            }
          }
        }
      }
    }
  `)

  const [ companies, setCompanies ] = useState([
    ...edges.map(({ node }) => Api.parseCompany(node)),
  ] as Company[])

  useEffect(() => {
    (async () => {
      setCompanies(await Api.getCompanies())
    })()
  }, [])
  return (
    <Layout>
      <SEO
        title="Home"
        description="Hottest startups in the Valley"
      />
      <div id="home-page">
        <ExplanatoryBanner />
        <h1>Hottest startups in the Valley</h1>
        <div className="companies">
          {
            Array.isArray(companies) && companies.length > 0
              ? companies.map((company, i) => (
                  <CompanyProfile
                    vertical={i !== 3}
                    company={company}
                    key={company.id}
                  />
              ))
              : <Spinner />
          }
        </div>
      </div>
    </Layout>
  )
}

export default Home

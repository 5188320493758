export class StatusCodes {
  static Unauthorized = 401
}

export class Environment {
  static development = `development`
  static staging = `staging`
  static production = `production`
}

export class EnvironmentVariables {
  static BASE_DOMAIN = process.env.GATSBY_BASE_DOMAIN
  static API_BASE_URL = process.env.GATSBY_API_BASE_URL
  static ENVIRONMENT = process.env.GATSBY_UV_ENVIRONMENT
  static FACEBOOK_APP_ID = process.env.GATSBY_FACEBOOK_APP_ID
  static GOOGLE_CLIENT_ID = process.env.GATSBY_GOOGLE_CLIENT_ID
  static SENTRY_DSN = process.env.SENTRY_DSN
}
import qs from 'qs'
import React, { useCallback } from 'react'
import { IoLogoFacebook } from 'react-icons/io'
import { SecondaryButton } from '../Button'
import Styles from './Facebook.module.scss'

interface Props {
  display?: string,
  href: string,
  quote: string,
  hashtag?: string,
  redirectUri?: string,
}

const FacebookButton: React.FC<Props> = ({
  // display = `popup`,
  href,
  quote,
  // hashtag,
  // redirectUri,
}) => {
  const onClick = useCallback(() => {
    // const parameters = qs.stringify({
    //   appId: EnvironmentVariables.FACEBOOK_APP_ID,
    //   display,
    //   hashtag,
    //   href,
    //   quote,
    //   redirectUri,
    // })
    // window.open(
    //   `https://www.facebook.com/dialog/share?${parameters}`,
    //   `_blank`,
    // )
    const parameters = qs.stringify({
      quote,
      u: href,
    })
    window.open(
      `https://www.facebook.com/sharer/sharer.php?${parameters}`,
      `_blank`,
    )
  }, [href, quote])
  return (
    <SecondaryButton className={Styles.facebook} onClick={onClick}>
      <IoLogoFacebook  size="1.1em" />&nbsp;SHARE
    </SecondaryButton>
  )
}

export default FacebookButton
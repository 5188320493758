import { graphql, useStaticQuery } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import React from 'react'
import Collapsible from 'react-collapsible'
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io'
import Layout from '../../components/Layout'
import SEO from '../../components/SEO'
import './FAQ.scss'

const FAQ: React.FC<{}> = () => {
  const {
    allMdx: {
      edges: faqs,
    },
  } = useStaticQuery(graphql`
    query FAQQuery {
      allMdx(filter: { fields: { type: { eq: "faq" } } } ) {
        edges {
          node {
            body
            frontmatter {
              title
            }
            fields {
              slug
            }
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO
        title="FAQs"
      />
      <div id="faq-page">
        <h1>Frequently Asked Questions</h1>

            <div className="faq-container">
          {
            faqs.map(({ node }) => {
              const {
                frontmatter: {
                  title,
                },
                body,
              } = node

              return (
                <div className="faq">
                  <Collapsible
                    trigger={
                      <div className="question">
                        <div>{title}</div>
                        <IoIosArrowDown className="open" size="1.5rem" />
                        <IoIosArrowUp className="closed" size="1.5rem" />
                      </div>
                    }
                    transitionTime={50}
                  >
                    <div className="answer">
                      <MDXRenderer>
                        {body}
                      </MDXRenderer>
                    </div>
                  </Collapsible>
                  <div></div>
                </div>

              )
            })
          }
        </div>

      </div>
    </Layout>
  )
}

export default FAQ
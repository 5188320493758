import { graphql, useStaticQuery } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import React from 'react'

interface Props {
  className?: string,
  style?: React.CSSProperties,
  children?: React.ReactNode,
  gradient?: string
}

const Satellite: React.FC<Props> = ({ className, style, children, gradient = `` }) => {
  const {
    file: {
      childImageSharp: {
        fluid: imageData,
      },
    },
  } = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "satellite.jpg" } ) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <BackgroundImage
      fluid={[gradient, imageData]}
      className={[`satellite`, className].join(` `)}
      style={style}
    >
      {children}
    </BackgroundImage>
  )
}

export default Satellite
import { graphql, useStaticQuery } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import React from 'react'

interface Props {
  children?: React.ReactNode,
}

const Garage: React.FC<Props> = ({
  children,
}) => {
  const {
    file: {
      childImageSharp: {
        fluid: imageData,
      },
    },
  } = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "garage.jpg" } ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  
  return (
    <BackgroundImage
      className="garage"
      fluid={imageData}
    >
      {children}
    </BackgroundImage>
  )
}

export default Garage
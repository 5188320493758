import React from 'react'
import './Testimonial.scss'

interface Props {
  children: string,
  cite: string | React.ReactNode,
}

const Testimonial: React.FC<Props> = ({
  children,
  cite,
}) => (
  <blockquote className="testimonial">
    <em>{children}</em>
    <footer>{cite}</footer>
  </blockquote>
)

export default Testimonial

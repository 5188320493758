import React, { useCallback, useState } from 'react'
import ReactMde, { Command } from 'react-mde'
import 'react-mde/lib/styles/css/react-mde-all.css'
import Markdown from '../../lib/Markdown'
import './MarkdownInput.scss'

const strikethrough: Command = {
  execute: options => {
    options.textApi.replaceSelection(`~~${options.initialState.selectedText}~~`)
    if(options.initialState.selectedText.length === 0){
      options.textApi.setSelectionRange({
        end: options.initialState.selection.end + 2,
        start: options.initialState.selection.start + 2,
      })
    }
  },
  icon: () => (
    <span>s̶t̶r̶i̶k̶e̶</span>
  ),
}

interface Props {
  value: string,
  onChange: (s: string) => void,
  placeholder: string,
  maxLength: number,
}

const MarkdownInput: React.FC<Props> = ({ value, onChange, placeholder, maxLength }) => {
  // eslint-disable-next-line quotes
  const [selectedTab, setSelectedTab] = useState<"write" | "preview">(`write`)
  const generateHTML = useCallback(
    markdown => Promise.resolve(Markdown.render(markdown.length > 0 ? markdown: placeholder)),
    [placeholder],
  )

  return (
    <ReactMde
      classes={{reactMde: `markdown-input`}}
      toolbarCommands={[[`bold`, `italic`, `link`, `unordered-list`, `ordered-list`, `strike`]]}
      value={value}
      childProps={{textArea: { maxLength, placeholder }}}
      onChange={onChange}
      generateMarkdownPreview={generateHTML}
      selectedTab={selectedTab}
      onTabChange={setSelectedTab}
      commands={{
        strike: strikethrough,
      }}
    />
  )
}

export default MarkdownInput
import React from 'react'
import './Buttons.scss'
import Facebook from './Facebook'
import Linkedin from './Linkedin'
import Share from './Share'
import Twitter from './Twitter'

interface Props {
  title: string,
  description: string,
  url: string,
}

const ShareButtons: React.FC<Props> = ({
  title,
  description,
  url,
}) => {
  return (
    <div className="share-buttons">
      <Share title={title} text={description} url={url} />
      <Twitter text={title} url={url} />
      <Facebook quote={title} href={url} />
      <Linkedin title={title} url={url} summary={description}/>
    </div>
  )
}

export default ShareButtons

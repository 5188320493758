import { Link } from 'gatsby'
import qs from 'qs'
import React, { useCallback, useEffect, useState } from 'react'
import { IoIosClose } from 'react-icons/io'
import LocalStorage from '../../../lib/LocalStorage'
import './ExplanatoryBanner.scss'

const ExplanatoryBanner = () => {

  const [showBanner, setShowBanner] = useState(true)

  useEffect(() => {
    if(showBanner){
      const parameters = qs.parse(window.location.search, { ignoreQueryPrefix: true })
      const { smart } = parameters

      if((smart && smart.length > 0) || LocalStorage.getShowExplanation() !== null){
        setShowBanner(false)
      }
    }
  }, [])

  const closeBanner = useCallback(() => {
    setShowBanner(false)
    LocalStorage.setShowExplanation()
  }, [])

  return showBanner ? (
    <div className="explanatory-banner">
      <div>
        <p>
          <strong>👋 Welcome to Unicorn Valley</strong>
          - the greatest startup valley you've never heard of. Peruse the many promising startups below, or
          get started <Link to="/companies/edit">founding your own unicorn</Link>.
        </p>
        <p>
          Also popular: <Link to="/about">WTF is this???</Link>
        </p>
      </div>
      <div><IoIosClose size="1.5rem" onClick={closeBanner}/></div>
    </div>
  ) : null
}

export default ExplanatoryBanner
import { Link, navigate } from 'gatsby'
import React, { useCallback, useContext, useState } from 'react'
import { FacebookLoginButton, GoogleLoginButton } from '../../components/Button'
import Layout from '../../components/Layout'
import SEO from '../../components/SEO'
import UserContext from '../../components/UserContext'
import Api from '../../lib/Api'
import Cookie from '../../lib/Cookie'
import Logger from '../../lib/Logger'
import { getRandomElementFromArray } from '../../lib/Utils'
import './Login.scss'

const randomQuip = getRandomElementFromArray([
  `this is a place for crafting pleasant nonsense`,
  `please note that you are entering a no-nonsense site which serious businesspeople use for serious business purposes`,
  `nonsense enlivens the brain`,
  `get cracking, that money isn't going to spend itself`,
])

// eslint-disable-next-line sonarjs/cognitive-complexity
const LoginPage: React.FC<{}> = () => {
  const [error, setError] = useState(``)
  const [fbLoggingIn, setFbLoggingIn] = useState(false)
  const [ggLoggingIn, setGgLogginIn] = useState(false)

  const { updateMe } = useContext(UserContext)

  const onOAuthResponse = useCallback((oauthType) => async (response) => {
    const setInProgress = oauthType === Api.oauthTypes.Facebook ? setFbLoggingIn : setGgLogginIn
    try {
      setError(``)
      setInProgress(true)

      if(response.error && response.details) {
        console.error(response.error, response.details)
        if(!response.details.includes(`Not a valid origin for the client`)){
          setError(response.error.details)
        }
        // otherwise, ignore
      } else if(response?.error === `popup_closed_by_user`){
        return // do nothing
      } else {
        const result = await Api.oauthLogin(oauthType, response)
        const { token, expiry, error } = result
        if(!error){
          Cookie.setJWT(token, new Date(expiry))
          setError(``)
          updateMe()
          navigate(`/`)
        } else {
          Logger.logError(error)
          setError(error)
        }
      }
    } catch (error) {
      Logger.logError(error)
      setError(`An error occurred whilst logging in`)
    } finally {
      setInProgress(false)
    }
  }, [updateMe])

  const onFacebookResponse = onOAuthResponse(Api.oauthTypes.Facebook)
  const onGoogleResponse = onOAuthResponse(Api.oauthTypes.Google)

  return (
    <Layout>
      <SEO
        title="Login"
      />
      <div id="login-page">
        <div className="login">
          <h3>Welcome</h3>
          <p>{randomQuip}</p>

          {(error && error.length > 0)
            ? (<p className="error">{error}</p>)
            : ``
          }

          <div className="oauth-container">
            <p>Login or register</p>
            <div className="oauth-options">
              <FacebookLoginButton
                isLoading={fbLoggingIn}
                onResponse={onFacebookResponse}
              />
              <GoogleLoginButton
                isLoading={ggLoggingIn}
                onResponse={onGoogleResponse}
              />
            </div>
          </div>

          <p>
            By using this site, you are agreeing to the&nbsp;
            <Link to="/legal/tos">Terms of Service</Link>
            &nbsp;and&nbsp;
            <Link to="/legal/privacy">Privacy Policy</Link>
          </p>
        </div>
      </div>
    </Layout>
  )
}

export default LoginPage
import React, { useCallback, useEffect, useState } from 'react'
import { IoMdShare } from 'react-icons/io'
import { SecondaryButton } from '../Button'
import Styles from './Share.module.scss'

interface Props extends ShareData {}

const ShareButton: React.FC<Props> = ({
  title,
  text,
  url,
}) => {
  const [canShare, setCanShare] = useState(false)

  const onClick = useCallback(() => {
    navigator.share({ text, title, url })
  }, [text, title, url])

  useEffect(() => {
    if(navigator.share){
      setCanShare(true)
    }
  }, [])

  return canShare ? (
    <SecondaryButton className={Styles.share} onClick={onClick}>
      <IoMdShare size="1.1em" />&nbsp;SHARE
    </SecondaryButton>
  ) : null
}

export default ShareButton

import { Link } from 'gatsby'
import React from 'react'
import type { User } from '../../types/unicornvalley'
import './UserProfile.scss'

const defaultSize = `normal`

interface Props {
  user: User,
  size?: typeof defaultSize | `big`,
}

const UserProfile: React.FC<Props> = ({
  user: {
    id,
    name,
    bio,
    picture,
  },
  size = defaultSize,
} = {
  size: defaultSize,
  user: {} as User,
} ) => (
  <Link className={`user-profile ${size}`} to={id ? `/users/${id}` : `#`}>
    <img src={picture} alt={name} />
    <div className="details">
      <strong>{name}</strong>
      <p>{bio}</p>
    </div>
  </Link>
)

export default UserProfile
import { RouteComponentProps } from '@reach/router'
import { Link, navigate } from 'gatsby'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { PrimaryButton } from '../../../components/Button'
import CheckboxInput from '../../../components/CheckboxInput'
import CompanyProfile from '../../../components/CompanyProfile'
import ImageInput from '../../../components/ImageInput'
import Layout from '../../../components/Layout'
import MarkdownInput from '../../../components/MarkdownInput'
import SEO from '../../../components/SEO'
import TextInput from '../../../components/TextInput'
import UserContext from '../../../components/UserContext'
import Api from '../../../lib/Api'
import Cookie from '../../../lib/Cookie'
import { getRandomElementFromArray } from '../../../lib/Utils'
import type { Company, Modify } from '../../../types/unicornvalley'
import './EditCompany.scss'


const inspiration = getRandomElementFromArray([
  `Scratch your own itch`,
  `Why should the glass be either half full or empty? Why not buy one, get one free?`,
  `Stop thinking about vitamins, painkillers, or candy. Get to the DNA of the problem.`,
  `Real founders don't need inspirational quotes to help them start founding`,
  `Stop reading inspirational quotes about sugar water and start changing the world`,
])

interface Props extends RouteComponentProps {
  // eslint-disable-next-line quotes
  id?: Company["id"],
}

interface LocalCompany extends Modify<Company, {
  uploadedPicture: File | null,
  uploadedPictureURL: string,
}> { }

const initialLocalCompany = {
  description: ``,
  isRemoved: false,
  link: ``,
  name: ``,
  picture: ``,
  tagline: ``,
  uploadedPicture: null,
  uploadedPictureURL: ``,
} as LocalCompany

// eslint-disable-next-line sonarjs/cognitive-complexity
const EditCompany: React.FC<Props> = (props) => {
  const { id: companyId } = props

  const [company, setCompany] = useState(initialLocalCompany)
  const [error, setError] = useState(``)
  const [isSaving, setIsSaving] = useState(false)

  const {
    name,
    description,
    tagline,
    link,
    picture,
    uploadedPicture,
    uploadedPictureURL,
    isRemoved,
  } = company

  const { user: me } = useContext(UserContext)

  const onChangeCompany = useCallback((attribute) => (value) => {
    setCompany({
      ...company,
      [attribute]: value,
    })
  }, [company, setCompany])

  const onChangePicture = useCallback((files) => {
    if (files.length === 1) {
      setCompany({
        ...company,
        uploadedPicture: files[0],
        uploadedPictureURL: URL.createObjectURL(files[0]),
      })
    }
    if (files.length === 0) {
      setCompany({
        ...company,
        uploadedPicture: null,
        uploadedPictureURL: ``,
      })
    }
  }, [company, setCompany])

  const validateFields = useCallback(() => {
    if (name.length === 0) {
      throw new Error(`Name cannot be empty`)
    }
    if (description.length === 0) {
      throw new Error(`Description cannot be empty`)
    }
    if (tagline.length === 0) {
      throw new Error(`Tagline cannot be empty`)
    }
    if (link.length > 0 && !link.match(/^https?:\/\/.*\..*/)) {
      throw new Error(`Link looks invalid`)
    }
  }, [description.length, link, name.length, tagline.length])

  const postCompany = useCallback(async () => {
    try {
      setIsSaving(true)
      validateFields()

      const token = Cookie.getJWT()
      const result = await Api.postCompany(token, {
        description,
        isRemoved,
        link,
        name,
        tagline,
      } as Company)
      if (uploadedPicture !== null) {
        await Api.uploadCompanyPicture(token, result.id, uploadedPicture)
      }
      navigate(`/companies/${result.id}`)
    } catch (error) {
      setError(error.message || `Could not post company`)
    } finally {
      setIsSaving(false)
    }
  }, [description, link, name, tagline, uploadedPicture, validateFields, isRemoved])

  const patchCompany = useCallback(async () => {
    try {
      setIsSaving(true)
      validateFields()

      const token = Cookie.getJWT()
      await Api.patchCompany(token, companyId, {
        description,
        isRemoved,
        link,
        name,
        tagline,
      } as Company)
      if (uploadedPicture !== null) {
        await Api.uploadCompanyPicture(token, companyId, uploadedPicture)
      }
      navigate(`/companies/${companyId}`)
    } catch (error) {
      setError(error.message || `Could not patch company`)
    } finally {
      setIsSaving(false)
    }
  }, [companyId, description, link, name, tagline, uploadedPicture, validateFields, isRemoved])

  useEffect((): any => {
    if (!Cookie.getJWT()) {
      return navigate(`/login`)
    }
    if (!me.id) { // only run once me is loaded
      return
    }

    (async () => {
      if (companyId && Object.entries(initialLocalCompany).every(([key, value]) => company[key] === value)) {
        const company = await Api.getCompany(companyId)

        if (me.id !== company.creator.id) {
          return navigate(`/companies/${companyId}`)
        }

        setCompany({
          ...initialLocalCompany,
          description: company.description,
          isRemoved: company.isRemoved,
          link: company.link,
          name: company.name,
          picture: company.picture,
          tagline: company.tagline,
        } as LocalCompany)
      }
    })()
  }, [me, companyId, name, tagline, description, company])

  return (
    <Layout>
      <SEO
        title={name.length > 0 ? `Edit ${name}` : `New Company`}
      />
      <div id="edit-company-page">
        <h2>{companyId ? `Make your pivot` : `Found a company`}</h2>
        <div className="edit-company">
          <p>
            <em>{inspiration}</em>
          </p>
          <p>
            Need more inspiration? Check out our&nbsp;
            <Link to="/inspiration">inspiration page</Link>.
          </p>
          <TextInput
            label="Name of the company"
            placeholder="what's it called?"
            onChangeText={onChangeCompany(`name`)}
            value={name}
          />
          <TextInput
            label="Tagline"
            placeholder={`what's so special about ${name.length > 0 ? name : `it`}?`}
            onChangeText={onChangeCompany(`tagline`)}
            value={tagline}
            maxLength={150}
          />
         <MarkdownInput
            value={description}
            onChange={onChangeCompany(`description`)}
            placeholder={`tell us all about ${name.length > 0 ? name : `the company`}\n\nfor best results, a description should be at least two tweets long (about 100 words)`}
            maxLength={3000}
          />

          <ImageInput
            label="Picture"
            onFilesChange={onChangePicture}
          />
          <TextInput
            label="Link (optional)"
            placeholder="Your amazing company website"
            onChangeText={onChangeCompany(`link`)}
            value={link}
            maxLength={120}
            type="url"
            pattern="^http(s)?://.*\..*"
          />

          {
            name.length > 0 ||
              tagline.length > 0 ||
              picture.length > 0
              ? (
                <div className="preview">
                  <h3>Preview</h3>
                  <CompanyProfile company={{
                    ...company,
                    isRemoved,
                    name: name.length > 0 ? name : `Company Name`,
                    picture: uploadedPictureURL.length > 0 ? uploadedPictureURL : picture,
                    tagline: tagline.length > 0 ? tagline : `a great tagline`,
                  } as Company} />
                </div>
              ) : null
          }

          <CheckboxInput
            label="Hidden - keep the company in stealth mode before launch"
            value={isRemoved}
            onChange={onChangeCompany(`isRemoved`)}
          />

          {error.length > 0
            ? (<p className="error">{error}</p>)
            : ``
          }

          <PrimaryButton
            onClick={companyId ? patchCompany : postCompany}
            isLoading={isSaving}
            loadingText="SAVING…"
          >
            SAVE
          </PrimaryButton>
        </div>
      </div>
    </Layout>
  )
}

export default EditCompany

import qs from 'qs'
import React, { useCallback } from 'react'
import { IoLogoTwitter } from 'react-icons/io'
import { SecondaryButton } from '../Button'
import Styles from './Twitter.module.scss'

interface Props {
  text: string,
  url: string,
  hashtags?: string,
  via?: string,
}

const TwitterButton: React.FC<Props> = ({
  text,
  url,
  hashtags,
  via,
}) => {
  const onClick = useCallback(() => {
    const parameters = qs.stringify({
      hashtags,
      text,
      url,
      via,
    })
    const href = `https://twitter.com/intent/tweet?${parameters}`
    window.open(href,`_blank`)
  }, [hashtags, text, url, via])
  return (
    <SecondaryButton className={Styles.twitter} onClick={onClick}>
      <IoLogoTwitter  size="1.1em" />&nbsp;TWEET
    </SecondaryButton>
  )
}

export default TwitterButton
import React from 'react'
import { Garage, Satellite } from '../../components/Images'
import Layout from '../../components/Layout'
import SEO from '../../components/SEO'
import Testimonial from '../../components/Testimonial'
import { FacebookText, GoogleText } from '../../components/Typography'
import './About.scss'

const About: React.FC<{}> = () => (
  <Layout>
    <SEO
      title="About"
    />
    <div id="about-page">
      <div className="intro">
        <h1>Start Founding, Here and Now</h1>
        <p>
          Unicorn Valley is a safe and easy-to-use platform for founding potential unicorns. Anyone can launch their startup within seconds, regardless of the quality of their idea.
        </p>
      </div>

      <div className="media-mentions">
        {/* TODO */}
      </div>

      <div className="selling-points-container">
        <div className="checklist">
          <table>
            <thead>
              <tr>
                <th></th>
                <th>S Valley</th>
                <th>Unicorn Valley</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Remote Work</td>
                <td>
                  ❌
                  <br />
                  <a
                    href="https://arstechnica.com/tech-policy/2020/05/facebook-workers-get-remote-work-option-but-it-could-come-with-a-pay-cut/"
                  >
                    comes with pay cut
                  </a>
                </td>
                <td>✅<br />work from anywhere</td>
              </tr>
              <tr>
                <td>Visa</td>
                <td>❌<br />H1B visa required</td>
                <td>✅<br />no visa required</td>
              </tr>
              <tr>
                <td>Cost of Housing</td>
                <td>❌<br />ridiculous, crippling</td>
                <td>✅<br />zero</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="selling-points">
          <div className="point">
            <h2>Snazzy Tech</h2>
            <p>
              We use only bleeding-edge versions of the coolest JS frameworks. We migrate our codebase every few weeks to the hottest new stack on Hacker News.
            </p>
          </div>
          <div className="point">
            <h2>Fast-Moving</h2>
            <p>
              No QA, no polish, no blockers. Nothing stands between our code and you. Our code is so fresh, if you want it any fresher, you'll have to write it yourself.
            </p>
          </div>
          <div className="point">
            <h2>Free Credits</h2>
            <p>
              Like any proper startup we don't have a monetisation strategy and we spend too much time coding to think of one, so in the meantime you get to use Unicorn Valley for free.
            </p>
          </div>
          <div className="point">
            <h2>Crypto Cred</h2>
            <p>
              <a href="https://twitter.com/jimmy_wales/status/1226868636020805632">Unlike Jimmy Wales</a>, we store our valuable data on an award-winning blockchain platform. Watch out for our upcoming cryptocurrrency — aptly named BitCorn 🌽.
            </p>
          </div>
        </div>
      </div>
      
      <div className="founding-myth-container">
        <Garage />
        <div className="founding-myth">
          <h2>Obligatory Apocryphal Founding Myth</h2>
          <p>
            Not too long ago, our founders were slowly coming to terms with the growing realisation that they would shortly exhaust their runway, and be forced across the thin line separating founders from unemployment. Contemplating the loss of their C-suite titles, it was then that they realised the source of their failure and a potential opportunity.
          </p>
          <p>
            Clearly, their lack of success was entirely attributable to the absence of a garage. This glaring omission quickly remedied, they turned their attention to the opportunity: the vast majority of people in the world are in fact non-founders. The brains of the billions and billions of these non-founders, all of whom obviously
            harbouring a secret desire to found unicorns, remained unpicked. And so Unicorn Valley was born.
          </p>
        </div>
      </div>

      <div className="mission-container">
        <Satellite
          style={{backgroundPosition: `top right` }}
          gradient='linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1))'
        >
          {/* <div className="pre-mission-copy">
            <p>
              Reality is frequently disappointing.
            </p>
            <p>
              We believe that everyone, everywhere, should be able to share in the sanity, exuberance, and solutionism unique to the Valley.
            </p>
          </div> */}
          <div className="mission">
            <h2>Our continuing mission</h2>
            <p>
              To <FacebookText>connect</FacebookText> the world with the Valley,
            </p>
            <p>
              and <GoogleText>organise</GoogleText> the trillions of billion-dollar ideas within.
            </p>
          </div>
        </Satellite>
      </div>

      <div className="testimonials-container">
        <div className="testimonials-copy">
          <h3>What we overheard people saying about us</h3>
        </div>
        <div className="testimonials">
          <Testimonial cite="Alice & Bob, Cryptographers">
            This site uses HTTPS so everything on it must be true.
          </Testimonial>
          <Testimonial cite="Sovereign Wealth Fund of Antarctica">
            We believe Bitcorn's innovative proof of sloth consensus algorithm
            has the potential to massively reduce our carbon footprint… possibly even
            reverse climate change and rejuvenate our ice caps.
          </Testimonial>
          <Testimonial
            cite={
              <>
                <a href="https://twitter.com/elonmusk/status/1276418907968925696">Elon Musk</a>
              </>
            }
          >
            Who controls the memes, controls the Universe
          </Testimonial>
          <Testimonial cite={
            <>
              <a href="https://twitter.com/2ndCougars/status/596378857680281601">Two random second-graders</a>
            </>
          }>
            …Unicorn Valley. It's really good.
          </Testimonial>
        </div>
      </div>
    </div>
  </Layout>
)

export default About

import React from 'react'
import GoogleLogin from 'react-google-login'
import { EnvironmentVariables } from '../../../lib/Constants'
import SecondaryButton from '../SecondaryButton'
import GoogleLogo from './google.svg'
import './GoogleLoginButton.scss'

interface Props {
  onResponse: any,
  text?: string,
  isLoading: boolean
}

const GoogleLoginButton: React.FC<Props> = ({
  onResponse,
  text = `Google`,
  isLoading = false,
}) => {
  return (
    <GoogleLogin
      autoLoad={false}
      clientId={EnvironmentVariables.GOOGLE_CLIENT_ID}
      onSuccess={onResponse}
      onFailure={onResponse}
      cookiePolicy={`single_host_origin`}
      render={({ onClick }) => (
        <SecondaryButton
          onClick={onClick}
          className="google-login-button"
          isLoading={isLoading}
          loadingText={
            <>
              <img src={GoogleLogo} alt="Google Logo" />
              <span>Logging in…</span>
            </>
          }
        >
          <img src={GoogleLogo} alt="Google Logo" />
          <span>{text}</span>
        </SecondaryButton>
      )}
    />
  )
}

export default GoogleLoginButton
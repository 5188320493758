import { RouteComponentProps } from '@reach/router'
import React, { useContext, useEffect, useState } from 'react'
import { IoMdCreate } from 'react-icons/io'
import { SecondaryButton } from '../../../components/Button'
import Layout from '../../../components/Layout'
import SEO from '../../../components/SEO'
import ShareButton from '../../../components/ShareButton'
import Spinner from '../../../components/Spinner'
import { Warning } from '../../../components/Typography'
import UserContext from '../../../components/UserContext'
import UserProfile from '../../../components/UserProfile'
import Api from '../../../lib/Api'
import Logger from '../../../lib/Logger'
import Markdown from '../../../lib/Markdown'
import type { Company, RawCompany, User } from '../../../types/unicornvalley'
import './CompaniesDetail.scss'

interface Props extends RouteComponentProps {
  // eslint-disable-next-line quotes
  id?: Company["id"],
  pageContext?: {
    company: RawCompany,
  }
}

// eslint-disable-next-line sonarjs/cognitive-complexity
const CompaniesDetailPage: React.FC<Props> = (props) => {
  const isSSR = !!props?.pageContext?.company
  const companyId = props?.pageContext?.company?.company_id || props.id
  const [
    {
      name,
      tagline,
      picture,
      description,
      link,
      creator = {} as User,
      isRemoved,
    },
    setCompany,
  ] = useState({
    ...(isSSR ? Api.parseCompany(props?.pageContext?.company) : {}),
  } as Company)
  const [isLoading, setIsLoading] = useState(false)

  const { user: me } = useContext(UserContext)
  const canEdit = (
    creator.id &&
    creator.id === me.id
  )

  useEffect(() => {
    (async () => {
      !isSSR && setIsLoading(true)
      try {
        setCompany(await Api.getCompany(companyId))
      } catch (error) {
        Logger.logError(error)
        window.location.replace(`/404`)
      }
      setIsLoading(false)
    })()
  }, [isSSR, companyId])

  const url = typeof window !== `undefined` && window.location.href

  return (
    <Layout>
      <SEO
        title={name}
        description={`${tagline}. ${description}`}
        image={picture}
        type="product"
      />
      <div id="companies-detail-page">
        {
          isLoading ? (
            <Spinner />
          ) : (
              <>
                <div className="top-info">
                  <div style={{ backgroundImage: `url("${picture}")` }} />
                  <div className="content">
                    <h3>
                      {
                        (link && link.length > 0)
                          ? <a href={link}>{name}</a>
                          : name
                      }
                    </h3>
                    <p>{tagline}</p>
                  </div>
                </div>
                <div className="company">
                  <div className="action-buttons">
                    <div className="left">
                    </div>
                    <div className="right">
                      {
                        canEdit ? (
                          <SecondaryButton className="edit-button" to={`/companies/${companyId}/edit`}>
                            <IoMdCreate />&nbsp;
                          EDIT
                          </SecondaryButton>
                        ) : null
                      }
                    </div>
                  </div>
                  {
                    isRemoved
                      ? (
                        <Warning>This company is in stealth mode and will not appear on the main page.</Warning>
                      ) : null
                  }
                  <div
                    className="description"
                    dangerouslySetInnerHTML={{
                      __html: Markdown.render(description),
                    }}
                  />
                  <UserProfile user={creator} />
                  <ShareButton.Buttons
                    title={`${name} - ${tagline}`}
                    description={description}
                    url={url}
                  />
                </div>
              </>
            )
        }
      </div>
    </Layout>
  )
}

export default CompaniesDetailPage

// export const query = graphql(`
//   query()
// `)